import React from "react";
import theme from "theme";
import {
  Theme,
  Link,
  Image,
  Box,
  Section,
  Text,
  LinkBox,
  Icon,
} from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import {
  TiSocialFacebookCircular,
  TiSocialLinkedinCircular,
} from "react-icons/ti";
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Hefi</title>
        <meta
          name={"description"}
          content={
            "Unlike traditional credit scoring models that rely on historical data, our system takes into account your spending and saving habits. This means that you will get a more accurate view of your creditworthiness, even if your past financial history doesn't accurately reflect your financial situation.  "
          }
        />
        <meta property={"og:title"} content={"Credit Scoring"} />
        <meta
          property={"og:image"}
          content={
            "https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z"
          }
        />
        <link
          rel={"shortcut icon"}
          href={
            "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z"
          }
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={
            "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z"
          }
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={
            "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z"
          }
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={
            "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z"
          }
        />
        <link
          rel={"apple-touch-startup-image"}
          href={
            "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z"
          }
        />
        <meta
          name={"msapplication-TileImage"}
          content={
            "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z"
          }
        />
        <meta name={"msapplication-TileColor"} content={"#000848"} />
      </Helmet>
      <Section
        padding="36px 0 36px 0"
        quarkly-title="Header"
        background="#0f0f55"
        sm-background="#0b0028"
        border-radius={0}
      >
        <Override slot="SectionContent" flex-direction="row" />
        <Box
          empty-border-width="1px"
          empty-border-style="solid"
          width="30%"
          sm-width="50%"
          align-items="center"
          empty-min-width="64px"
          empty-min-height="64px"
          empty-border-color="LightGray"
          display="flex"
        >
          <Image
            height="auto"
            src="https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Hefl%20logo.svg?v=2023-03-20T06:31:08.270Z"
            width="100%"
            max-width="171px"
          />
        </Box>
      </Section>
      <Section
        padding="36px 0 80px 0"
        quarkly-title="HeroBlock"
        md-padding="36px 0 60px 0"
        sm-background="url(https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/image.jpg?v=2023-03-20T06:22:18.899Z=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000) 0% 0%/cover no-repeat scroll"
        md-background="url(https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/image.jpg?v=2023-03-20T06:22:18.899Z=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000) 0% 0%/cover no-repeat scroll"
        background="url(https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/image.jpg?v=2023-03-20T06:22:18.899Z) 0% 0% /cover repeat scroll padding-box"
        height="600px"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          position="relative"
          z-index="9"
          md-flex-wrap="wrap"
          md-z-index="7"
          display="flex"
        />
        <Image
          src="https://images.unsplash.com/photo-1586245510994-1ba3fb3a5ef2?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&h=2000"
          sm-overflow-x="hidden"
          display="none"
        />
        <Box
          empty-min-width="64px"
          empty-min-height="64px"
          empty-border-width="1px"
          empty-border-style="solid"
          empty-border-color="LightGray"
          flex-direction="column"
          display="flex"
          align-items="flex-start"
          lg-padding="0px 90px 0px 0px"
          md-width="100%"
          justify-content="center"
          width="100%"
          padding="0px 180px 0px 0px"
          md-padding="0px 0px 0px 0px"
        >
          <Box
            lg-margin="32px 0px 0px 0px"
            md-width="100%"
            md-margin="36px 0px 0px 0px"
            display="flex"
            margin="72px 0px 0px 0px"
          >
            <Link
              padding="15px 30px 15px 30px"
              font="--lead"
              border-radius="10px"
              border-width="2px"
              transition="background-color 0.3s --transitionTimingFunction-easeInOut 0s"
              lg-font='normal 500 18px/1.6 "Inter", sans-serif'
              border-style="solid"
              md-width="50%"
              md-font='normal 500 16px/1.6 "Inter", sans-serif'
              md-text-align="center"
              sm-padding="15px 13px 15px 13px"
              text-decoration-line="initial"
              color="--white"
              background="rgba(255, 5, 5, 0)"
              margin="0px 0px 0px 0px"
              border-color="rgba(255, 255, 255, 0.3)"
              sm-font='normal 500 18px/1.6 "Inter", sans-serif'
              hover-background="--color-white"
              hover-color="--indigo"
              href="/login"
              lg-padding="15px 23px 15px 23px"
              md-padding="15px 16px 15px 16px"
              display="none"
            >
              Learn more
            </Link>
          </Box>
        </Box>
      </Section>
      {/* <Section
        padding="160px 0 24px 0"
        sm-padding="80px 0 24px 0"
        md-padding="50px 0 24px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
        />
        <Box
          width="50%"
          flex-direction="column"
          md-width="100%"
          empty-min-width="64px"
          empty-border-style="solid"
          empty-border-color="LightGray"
          padding="16px 16px 16px 16px"
          display="flex"
          align-items="flex-start"
          justify-content="space-between"
          empty-min-height="64px"
          empty-border-width="1px"
        >
          <Image
            position="static"
            lg-left="0px"
            md-width="100%"
            lg-max-height="366px"
            margin-top="10px"
            src="https://images.unsplash.com/photo-1677442135703-1787eea5ce01?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000"
            left="-140px"
            max-width="100%"
            object-fit="fill"
            align-self="auto"
            display="inline-block"
            flex="1 1 0%"
          />
        </Box>
        <Box
          justify-content="space-between"
          empty-min-height="64px"
          empty-border-style="solid"
          width="50%"
          padding="16px 16px 16px 16px"
          display="flex"
          md-width="100%"
          empty-min-width="64px"
          empty-border-width="1px"
          empty-border-color="LightGray"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            width="85%"
            lg-width="100%"
            lg-font='normal 700 34px/1.2 "Inter", sans-serif'
            md-font='normal 700 24px/1.2 "Inter", sans-serif'
            margin="0px 0px 16px 0px"
            font="normal 600 50px/1.2 --fontFamily-googleInter"
            color="--indigo"
            sm-font='normal 800 30px/1.2 "Inter", sans-serif'
          >
            Credit scoring - reinvented.
          </Text>
          <Text
            font="--base"
            opacity=".7"
            lg-font='normal 400 16px/1.5 "Inter", sans-serif'
            md-font='normal 400 14px/1.5 "Inter", sans-serif'
            margin="0px 0px 25px 0px"
            color="--dark"
          >
            Unlike traditional credit scoring models that rely on historical
            data, our system takes into account your spending and saving habits.
            This means that you will get a more accurate view of your
            creditworthiness, even if your past financial history doesn't
            accurately reflect your financial situation.
          </Text>
        </Box>
      </Section> */}
      {/* <Section
        md-padding="60px 0 60px 0"
        padding="84px 0 60px 0"
        sm-padding="60px 0 60px 0"
      >
        <Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
        
    
        <Box
          empty-min-height="64px"
          empty-border-width="1px"
          empty-border-color="LightGray"
          empty-min-width="64px"
          empty-border-style="solid"
          padding="16px 16px 16px 16px"
          width="33.333%"
          md-width="100%"
          sm-width="100%"
          lg-width="50%"
        >
          <LinkBox
            border-color="rgba(66, 82, 207, 0.1)"
            lg-height="100%"
            overflow-x="hidden"
            overflow-y="hidden"
            height="100%"
            width="100%"
            flex-direction="column"
            border-style="solid"
            border-width="2px"
            border-radius="10px"
            padding="32px 32px 32px 32px"
            display="flex"
            justify-content="flex-start"
            md-min-height="200px"
            lg-min-height="350px"
            align-items="center"
          >
            <Image
              width="54px"
              src="https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/noun-saving-4392416.svg?v=2023-03-15T07:05:50.990Z"
              display="block"
            />
            <Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
              Invest in what matters to you
            </Text>
            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
              Build a portfolio that aligns with your values and financial
              goals, and stay on top of your investments with our easy-to-use
              platform and partners.
            </Text>
          </LinkBox>
        </Box>
        <Box
          empty-min-height="64px"
          empty-border-width="1px"
          empty-border-color="LightGray"
          empty-min-width="64px"
          empty-border-style="solid"
          padding="16px 16px 16px 16px"
          width="33.333%"
          md-width="100%"
          sm-width="100%"
          lg-width="50%"
        >
          <LinkBox
            border-color="rgba(66, 82, 207, 0.1)"
            lg-height="100%"
            overflow-x="hidden"
            overflow-y="hidden"
            height="100%"
            width="100%"
            flex-direction="column"
            border-style="solid"
            border-width="2px"
            border-radius="10px"
            padding="32px 32px 32px 32px"
            display="flex"
            justify-content="flex-start"
            md-min-height="200px"
            lg-min-height="350px"
            align-items="center"
          >
            <Image
              width="48px"
              src="https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Group%201000002780.svg?v=2023-03-15T07:05:50.993Z"
              display="block"
            />
            <Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
              Begin saving today
              <br />
            </Text>
            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
              At our company, we understand the importance of saving for your
              future. Whether you're saving for a rainy day or retirement, we
              can provide the security solutions that makes saving for your
              future self a priority.
            </Text>
          </LinkBox>
        </Box>
        
        <Box
          empty-min-height="64px"
          empty-border-width="1px"
          empty-border-color="LightGray"
          empty-min-width="64px"
          empty-border-style="solid"
          padding="16px 16px 16px 16px"
          width="33.333%"
          md-width="100%"
          sm-width="100%"
          lg-width="50%"
        >
          <LinkBox
            border-color="rgba(66, 82, 207, 0.1)"
            lg-height="100%"
            overflow-x="hidden"
            overflow-y="hidden"
            height="100%"
            width="100%"
            flex-direction="column"
            border-style="solid"
            border-width="2px"
            border-radius="10px"
            padding="32px 32px 32px 32px"
            display="flex"
            md-min-height="200px"
            lg-min-height="350px"
            align-items="center"
            justify-content="flex-start"
          >
            <Image
              width="48px"
              src="https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/real-time%20insights.svg?v=2023-03-15T07:05:50.981Z"
              display="block"
            />
            <Text color="--indigo" font="--lead" margin="32px 0px 8px 0px">
              Financial freedom
            </Text>
            <Text color="--dark" font="--textBase" margin="0px 0px 0px 0px">
              Ensuring you retain control and security over your financial
              future is our priority. We are building powerful tools to help you
              make informed decision and empower you with the knowledge and
              skills you need to achieve your financial goals.
              <br />
            </Text>
          </LinkBox>
        </Box>
      </Section> */}
      {/* <Section
        lg-padding="60px 0 40px 0"
        padding="60px 0 100px 0"
        md-padding="30px 0 30px 0"
      >
        <Text
          md-margin="0px 0px 40px 0px"
          lg-margin="0px 0px 40px 0px"
          margin="0px 0px 80px 0px"
          font="--headline2"
          color="--indigo"
          lg-font='normal 700 34px/1.2 "Inter", sans-serif'
          md-font='normal 700 20px/1.2 "Inter", sans-serif'
        >
          The new way to financial inclusivity
        </Text>
        <Box
          display="flex"
          sm-margin="0px 0px 30px 0px"
          padding="0px 0px 0px 0px"
          md-border-width="0px 0px 2px 0px"
          margin="0px 0px 20px 0px"
          width="100%"
          border-style="solid"
          border-color="rgba(66, 82, 207, 0.1)"
          border-width="2px"
          border-radius="10px"
          flex-wrap="wrap"
          min-height="284px"
          md-border-radius="0px"
        >
          <Box
            md-order="-1"
            width="30%"
            display="flex"
            lg-width="50%"
            md-width="100%"
            overflow-x="auto"
            flex="1 1 0%"
          >
            <Image
              width="100%"
              border-radius="10px"
              md-margin="0px 0px 0px 0px"
              margin="-1px -1px -1px 0px"
              src="https://images.unsplash.com/photo-1651126433991-11dda0eb78ff?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000"
              position="static"
              object-fit="cover"
              flex="0 1 auto"
              order="1"
              align-self="flex-start"
              display="inline-block"
              sm-position="static"
              sm-align-self="flex-start"
            />
          </Box>
          <Box
            padding="32px 17% 32px 62px"
            width="70%"
            display="flex"
            position="relative"
            md-padding="32px 30px 32px 0px"
            flex-direction="column"
            justify-content="space-between"
            lg-padding="32px 30px 32px 32px"
            lg-width="50%"
            md-width="100%"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--headline4"
              color="--indigo"
              width="85%"
              lg-font='600 22px/1.3 "Inter", sans-serif'
            >
              Revolutionize your credit score with real-time data
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
              Our cutting-edge system uses real-time data contributors to
              provide you with the most accurate report of your current
              financial position. We help you to make sure your future position
              is where you want it to be. Say goodbye to struggling to save, and
              hello to a smarter way of managing your finances!
            </Text>
          </Box>
        </Box>
        <Box
          margin="0px 0px 20px 0px"
          sm-margin="0px 0px 30px 0px"
          border-radius="10px"
          min-height="284px"
          md-border-radius="0px"
          padding="0px 0px 0px 0px"
          border-style="solid"
          display="flex"
          flex-direction="row-reverse"
          border-width="2px"
          flex-wrap="wrap"
          md-border-width="0px 0px 2px 0px"
          width="100%"
          border-color="rgba(66, 82, 207, 0.1)"
          md-border-color="rgba(66, 82, 207, 0.1)"
        >
          <Box display="flex" lg-width="50%" md-width="100%" width="30%">
            <Image
              margin="-1px 0px -1px -1px"
              src="https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/2.png?v=2023-03-17T13:34:40.478Z"
              object-fit="cover"
              width="100%"
              border-radius="10px"
              md-margin="0px 0px 0px 0px"
            />
          </Box>
          <Box
            padding="32px 17% 32px 62px"
            width="70%"
            justify-content="space-between"
            md-width="100%"
            md-padding="32px 30px 32px 0px"
            display="flex"
            flex-direction="column"
            position="relative"
            lg-padding="32px 30px 32px 32px"
            lg-width="50%"
          >
            <Text
              color="--indigo"
              width="85%"
              lg-font='600 22px/1.3 "Inter", sans-serif'
              margin="0px 0px 8px 0px"
              font="--headline4"
            >
              Build your own future with a credit key
              <br />
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
              Giving you the best possible start in life. We can help you build
              nest eggs that will provide financial security for your future.
            </Text>
          </Box>
        </Box>
        <Box
          display="flex"
          width="100%"
          border-style="solid"
          border-radius="10px"
          min-height="284px"
          md-border-radius="0px"
          margin="0px 0px 20px 0px"
          sm-margin="0px 0px 30px 0px"
          padding="0px 0px 0px 0px"
          border-color="rgba(66, 82, 207, 0.1)"
          border-width="2px"
          flex-wrap="wrap"
          md-border-width="0px 0px 2px 0px"
        >
          <Box md-width="100%" width="30%" display="flex" lg-width="50%">
            <Image
              margin="-1px 0px -1px -1px"
              src="https://images.unsplash.com/photo-1543958892-44b098374a87?ixlib=rb-4.0.3&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=2000"
              object-fit="cover"
              width="100%"
              border-radius="10px"
              md-margin="0px 0px 0px 0px"
            />
          </Box>
          <Box
            md-width="100%"
            padding="32px 17% 32px 62px"
            display="flex"
            lg-width="50%"
            position="relative"
            lg-padding="32px 30px 32px 32px"
            md-padding="32px 30px 32px 0px"
            width="70%"
            flex-direction="column"
            justify-content="space-between"
          >
            <Text
              margin="0px 0px 8px 0px"
              font="--headline4"
              color="--indigo"
              width="85%"
              lg-font='600 22px/1.3 "Inter", sans-serif'
            >
              Invest in a fairer future
            </Text>
            <Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
              <br />
              We know investing can be complicated but we also know you want to
              start. We will help you untangle the complexities of financial
              jargon and start building your future, today. Whatever your
              priorities, we know you want a fairer, more sustainable future and
              we can help you to achieve it.
              <br />
            </Text>
          </Box>
        </Box>
      </Section> */}
      <Section
        lg-padding="60px 0 40px 0"
        padding="80px 40px 100px 40px"
        md-padding="30px 0 30px 0"
        display="flex"
        align-items="center"
      >
        <Text
          md-margin="0px 0px 40px 0px"
          lg-margin="0px 0px 40px 0px"
          margin="0px 0px 0px 0px"
          font="--headline4"
          font-size="1.5rem"
          color="--indigo"
          lg-font='normal 700 34px/1.2 "Inter", sans-serif'
          md-font='normal 700 20px/1.2 "Inter", sans-serif'
        >
          Our site is under construction. Please contact{" "}
          <a href="mailto:hello@hefi.ai">hello@hefi.ai</a> if you want to
          connect with us. We appreciate your patience and can't wait to show
          you our new site.
        </Text>
      </Section>
      <Section
        sm-padding="60px 0 60px 0"
        lg-padding="60px 0 100px 0"
        padding="60px 0 160px 0"
        display="none"
      >
        <Override
          slot="SectionContent"
          justify-content="center"
          align-items="center"
        />
        <Box
          width="87%"
          min-height="16px"
          height="30px"
          border-radius="30px 30px 0px 0px"
          background="--color-light"
          opacity="0.3"
        />
        <Box
          background="--color-light"
          opacity="0.4"
          sm-display="none"
          width="93%"
          min-height="16px"
          height="30px"
          border-radius="30px 30px 0px 0px"
        />
      </Section>
      <Section
        background="#111157"
        padding="100px 0 0px 0"
        md-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          justify-content="center"
          align-items="flex-start"
        />
        <Box
          empty-border-color="LightGray"
          flex-direction="column"
          md-justify-content="center"
          display="flex"
          padding="16px 16px 16px 16px"
          md-width="100%"
          empty-min-width="64px"
          empty-min-height="64px"
          empty-border-width="1px"
          empty-border-style="solid"
          width="25%"
          md-align-items="center"
          align-items="center"
          justify-content="flex-start"
        >
          <Image
            src="https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Hefl%20logo.svg?v=2023-03-20T06:31:08.270Z"
            margin="0px 0px 24px 0px"
            max-width="171px"
          />
          <LinkBox
            display="flex"
            flex-direction="row"
            align-self="center"
            align-content="space-around"
            flex-wrap="wrap"
          >
            <Link
              href="https://www.facebook.com/profile.php?id=100089728058327"
              target="_blank"
            >
              <Icon
                category="ti"
                icon={TiSocialFacebookCircular}
                size="64px"
                color="#ffffff"
                width="50px"
                height="50px"
              />
            </Link>
            <Link
              href="https://www.linkedin.com/company/hefiai"
              target="_blank"
            >
              <Icon
                category="ti"
                icon={TiSocialLinkedinCircular}
                size="64px"
                height="50px"
                width="50px"
                color="#ffffff"
              />
            </Link>
          </LinkBox>
        </Box>
      </Section>
      <Section
        background="#111157"
        padding="40px 0 0px 0"
        md-padding="40px 0 40px 0"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
          justify-content="center"
          align-items="flex-end"
        />
        <Box
          empty-border-color="LightGray"
          flex-direction="column"
          md-justify-content="center"
          display="flex"
          padding="16px 16px 24px 16px"
          md-width="100%"
          empty-min-width="64px"
          empty-min-height="64px"
          empty-border-width="1px"
          empty-border-style="solid"
          width="100%"
          text-align="center"
          md-align-items="center"
          align-items="center"
          justify-content="flex-start"
        >
          <Text
            margin="0px 0px 0px 0px"
            font="--textBase"
            color="#ffffff"
            font-size="0.75rem"
          >
            <br />
            Incorporation Number: 14725673 | Registered Office: Bonded
            Warehouse, 18 Lower Byrom Street, Manchester, M3 4AP, United
            Kingdom. <br />
            &copy; 2023 HEFI LIMITED
            <br />
          </Text>
        </Box>
      </Section>
      <RawHtml>
        <style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  );
};
