export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"640752f1aed6310020bcf292",
				"640752f1aed6310020bcf296"
			]
		},
		"640752f1aed6310020bcf292": {
			"id": "640752f1aed6310020bcf292",
			"name": "404",
			"pageUrl": "404"
		},
		"640752f1aed6310020bcf296": {
			"id": "640752f1aed6310020bcf296",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z",
			"favicon_32px": "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/640752f1aed6310020bcf27e/images/Capture.JPG?v=2023-03-17T14:37:03.072Z",
			"background_win10": "#000848",
			"og:title": "Credit Scoring",
			"og:description": "",
			"og:image": "https://uploads.quarkly.io/6123949e957019001e284458/images/OGimage.png?v=2021-09-21T16:25:40.647Z",
			"title": "Open Credit",
			"description": "Unlike traditional credit scoring models that rely on historical data, our system takes into account your current spending and saving habits. This means that you can get a more accurate view of your creditworthiness, even if your past financial history doesn't accurately reflect your current financial situation.",
			"robotsFileType": "autogenerated",
			"customSitemapDomain": "https://www.hefi.ai/",
			"sitemapFileType": "autogenerated"
		}
	}
}